/* eslint-disable max-len */
import { FC, memo } from 'react';

import * as styles from './styles';

type Props = {
  fileType?: string;
  href?: string;
};

const DownloadsIcon: FC<Props> = ({ fileType }) =>
  fileType ? (
    <>
      {fileType === 'exe' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          className={styles.fileIcon}
        >
          <path
            fillRule="evenodd"
            d="M5.5 22.25a.75.75 0 0 1-.75-.75v-19a.75.75 0 0 1 .75-.75h7.487a.75.75 0 0 1 .525.215l5.514 5.415a.75.75 0 0 1 .224.535V21.5a.75.75 0 0 1-.75.75h-13zm-2.25-.75a2.25 2.25 0 0 0 2.25 2.25h13a2.25 2.25 0 0 0 2.25-2.25V7.915a2.25 2.25 0 0 0-.673-1.605L14.563.895A2.25 2.25 0 0 0 12.987.25H5.5A2.25 2.25 0 0 0 3.25 2.5v19z"
            clipRule="evenodd"
          />
          <path d="M7.542 18h3.498v-.81H8.73l2.31-2.688V13.8H7.614v.81h2.238l-2.31 2.688V18zm4.116 0h.924v-4.2h-.924V18zm1.674 0h.924v-1.26h.702c.942 0 1.698-.504 1.698-1.476v-.012c0-.858-.606-1.452-1.608-1.452h-1.716V18zm.924-2.082v-1.284h.714c.462 0 .75.222.75.636v.012c0 .36-.27.636-.732.636h-.732z" />
          <path
            fillRule="evenodd"
            d="M20.75 12V9.071A1.75 1.75 0 0 0 19 7.321h-5.071a.25.25 0 0 1-.25-.25V2a1.75 1.75 0 0 0-1.75-1.75H9v1.5h2.929a.25.25 0 0 1 .25.25v5.071c0 .967.783 1.75 1.75 1.75H19a.25.25 0 0 1 .25.25V12h1.5z"
            clipRule="evenodd"
          />
        </svg>
      )}

      {fileType === 'zip' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          className={styles.fileIcon}
        >
          <path
            fillRule="evenodd"
            d="M5.5 22.25a.75.75 0 0 1-.75-.75v-19a.75.75 0 0 1 .75-.75h7.487a.75.75 0 0 1 .525.215l5.514 5.415a.75.75 0 0 1 .224.535V21.5a.75.75 0 0 1-.75.75h-13zm-2.25-.75a2.25 2.25 0 0 0 2.25 2.25h13a2.25 2.25 0 0 0 2.25-2.25V7.915a2.25 2.25 0 0 0-.673-1.605L14.563.895A2.25 2.25 0 0 0 12.987.25H5.5A2.25 2.25 0 0 0 3.25 2.5v19z"
            clipRule="evenodd"
          />
          <path d="M7.542 18h3.498v-.81H8.73l2.31-2.688V13.8H7.614v.81h2.238l-2.31 2.688V18zm4.116 0h.924v-4.2h-.924V18zm1.674 0h.924v-1.26h.702c.942 0 1.698-.504 1.698-1.476v-.012c0-.858-.606-1.452-1.608-1.452h-1.716V18zm.924-2.082v-1.284h.714c.462 0 .75.222.75.636v.012c0 .36-.27.636-.732.636h-.732z" />
          <path
            fillRule="evenodd"
            d="M20.75 12V9.071A1.75 1.75 0 0 0 19 7.321h-5.071a.25.25 0 0 1-.25-.25V2a1.75 1.75 0 0 0-1.75-1.75H9v1.5h2.929a.25.25 0 0 1 .25.25v5.071c0 .967.783 1.75 1.75 1.75H19a.25.25 0 0 1 .25.25V12h1.5z"
            clipRule="evenodd"
          />
        </svg>
      )}

      {fileType === 'pdf' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          className={styles.fileIcon}
        >
          <path
            fillRule="evenodd"
            d="M5.5 22.25a.75.75 0 0 1-.75-.75v-19a.75.75 0 0 1 .75-.75h7.487a.75.75 0 0 1 .525.215l5.514 5.415a.75.75 0 0 1 .224.535V21.5a.75.75 0 0 1-.75.75h-13zm-2.25-.75a2.25 2.25 0 0 0 2.25 2.25h13a2.25 2.25 0 0 0 2.25-2.25V7.915a2.25 2.25 0 0 0-.673-1.605L14.563.895A2.25 2.25 0 0 0 12.987.25H5.5A2.25 2.25 0 0 0 3.25 2.5v19z"
            clipRule="evenodd"
          />
          <path d="M6.485 18h.924v-1.26h.702c.942 0 1.698-.504 1.698-1.476v-.012c0-.858-.606-1.452-1.608-1.452H6.485V18zm.924-2.082v-1.284h.714c.462 0 .75.222.75.636v.012c0 .36-.27.636-.732.636h-.732zM10.193 18h1.638c1.32 0 2.232-.918 2.232-2.1v-.012c0-1.182-.913-2.088-2.232-2.088h-1.638V18zm.924-.834v-2.532h.713c.756 0 1.267.522 1.267 1.266v.012c0 .744-.51 1.254-1.266 1.254h-.714zm3.469.834h.924v-1.626h2.004v-.84H15.51v-.894h2.274v-.84h-3.198V18z" />
          <path
            fillRule="evenodd"
            d="M20.75 12V9.071A1.75 1.75 0 0 0 19 7.321h-5.071a.25.25 0 0 1-.25-.25V2a1.75 1.75 0 0 0-1.75-1.75H9v1.5h2.929a.25.25 0 0 1 .25.25v5.071c0 .967.783 1.75 1.75 1.75H19a.25.25 0 0 1 .25.25V12h1.5z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={styles.fileIcon}
    >
      <path
        fillRule="evenodd"
        d="M5.5 22.25a.75.75 0 0 1-.75-.75v-19a.75.75 0 0 1 .75-.75h7.487a.75.75 0 0 1 .525.215l5.514 5.415a.75.75 0 0 1 .224.535V21.5a.75.75 0 0 1-.75.75h-13zm-2.25-.75a2.25 2.25 0 0 0 2.25 2.25h13a2.25 2.25 0 0 0 2.25-2.25V7.915a2.25 2.25 0 0 0-.673-1.605L14.563.895A2.25 2.25 0 0 0 12.987.25H5.5A2.25 2.25 0 0 0 3.25 2.5v19z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M20.75 12V9.071A1.75 1.75 0 0 0 19 7.321h-5.071a.25.25 0 0 1-.25-.25V2a1.75 1.75 0 0 0-1.75-1.75H9v1.5h2.929a.25.25 0 0 1 .25.25v5.071c0 .967.783 1.75 1.75 1.75H19a.25.25 0 0 1 .25.25V12h1.5z"
        clipRule="evenodd"
      />
      <circle cx="9" cy="16.75" r=".75" />
      <circle cx="12" cy="16.75" r=".75" />
      <circle cx="15" cy="16.75" r=".75" />
    </svg>
  );
export default memo(DownloadsIcon);
