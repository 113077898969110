import { classnames } from '@tailwindcss-classnames';

// outer
export const downloadsSection = classnames(
  'grid',
  'grid-cols-12',
  'gap-2',
  'md:gap-8'
);

export const downloadsWrapper = classnames(
  'mb-4',
  'col-span-12',
  'text-nzxt-light-grey-800',
  'space-y-4',
  'md:order-1',
  'md:grid',
  'md:grid-cols-2',
  'md:gap-x-4',
  'md:space-y-0'
);

// inner
export const srOnly = classnames('sr-only');

export const downloadslistItem = classnames(
  'grid',
  'grid-cols-6',
  'gap-4',
  'w-full',
  'divide-y'
);

export const subheading = classnames(
  'font-bold',
  'text-nzxt-light-grey-600',
  'dark:text-nzxt-dark-grey-200',
  'uppercase',
  'md:text-lg',
  'mb-2'
);

export const detailItemWrapper = classnames('col-span-12');

export const downloadsValueLabel = classnames('font-semibold');
export const downloadsValueDetail = classnames(
  'pb-2',
  'w-full',
  'text-nzxt-light-grey-800'
);

export const buttonClass = classnames('flex', 'items-center');
export const icon = classnames('ml-1', 'text-base');
export const downloadsLinkContainer = classnames(
  downloadsValueDetail,
  'md:text-right'
);

export const downloadsDetails = classnames(
  'text-xs',
  'text-nzxt-light-grey-600',
  'dark:text-nzxt-dark-grey-200'
);
export const fileType = classnames('ml-1');

export const sectionHeading = classnames(
  'h3',
  'pb-3',
  'md:pb-6',
  'mb-3',
  'md:mb-6',
  'border-b'
);

export const downloadsGrid = classnames(
  'grid',
  'gap-x-6',
  'md:grid-cols-2',
  'mb-6',
  'md:mb-12'
);

const secondToLastItem = classnames('border-b-transparent');
export const downloadsGridItem = (isSecondToLast: boolean): string =>
  classnames(
    'block',
    'pt-2',
    'pb-2',
    'md:py-4',
    'last:border-b-transparent',
    'border-b',
    'flex',
    'space-x-4',
    {
      [secondToLastItem]: isSecondToLast,
    }
  );

export const fileIcon = classnames(
  'w-9',
  'h-9',
  'fill-current',
  'text-blue-600'
);
