import { FC, memo } from 'react';
import type { DatoProductDownloads } from '@framework/api/types';
import { IconDownload } from '@nzxt/react-icons';
import ButtonLink from '@components/ButtonLink';
import DownloadsIcon from './DownloadsIcon';

import * as styles from './styles';

type Props = {
  item: DatoProductDownloads;
  secondToLast?: boolean;
  fileType?: string;
};

const getReadableFileSizeString = (fileSizeInBytes: string): string => {
  let file = parseInt(fileSizeInBytes, 10);
  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB'];
  do {
    file /= 1024;
    i += 1;
  } while (file > 1024);

  return Math.max(file, 0.1).toFixed(1) + byteUnits[i];
};

const getHumanFileType = (fileType: string): string => {
  if (fileType.includes('zip')) {
    return 'zip';
  }
  if (fileType.includes('pdf')) {
    return 'pdf';
  }
  if (fileType.includes('exe')) {
    return 'exe';
  }
  return null;
};

const DownloadsItem: FC<Props> = ({ item, fileType, secondToLast = false }) =>
  item.file?.url ? (
    <div key={item.name} className={styles.downloadsGridItem(secondToLast)}>
      <a target="_blank" rel="noopener noreferrer" href={item.file.url}>
        <p className={styles.srOnly}>
          Download {item.name} {getHumanFileType(fileType) || 'Unknown File'}
        </p>
        <DownloadsIcon fileType={getHumanFileType(fileType)} />
      </a>
      {item.name && (
        <div className={styles.downloadsValueDetail} key={item.name}>
          <ButtonLink
            className={styles.buttonClass}
            noChevron
            href={item.file.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.name} <IconDownload className={styles.icon} />
          </ButtonLink>

          <div className={styles.downloadsDetails}>
            {item.file?.size && (
              <p>
                {getReadableFileSizeString(item.file.size)}
                {fileType && (
                  <span className={styles.fileType}>({fileType})</span>
                )}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  ) : null;

export default memo(DownloadsItem);
