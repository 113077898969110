import { classnames } from '@tailwindcss-classnames';

export const paddingBottom = classnames('pb-2');

export const buttonGroupContainer = (noPadding: boolean): string =>
  classnames({
    [paddingBottom]: !noPadding,
  });
export const regularMargin = classnames('mb-2');

export const buttonGroup = classnames('w-full');

export const helperText = classnames(
  'text-center',
  'text-nzxt-light-grey-800',
  'dark:text-nzxt-dark-grey-200',
  'text-sm',
  'my-1'
);

export const notifyText = classnames(
  'text-center',
  'text-nzxt-light-grey-800',
  'dark:text-nzxt-dark-grey-200',
  'text-xs',
  'mb-2'
);
export const cartButton = classnames('w-full');
export const cartGroup = classnames('flex', 'flex-col', 'w-full');
export const cartButtonGroup = classnames(
  'flex',
  'flex-col',
  'items-center',
  'md:items-start',
  'space-y-4'
);

const centeredText = classnames('text-center');
export const groupHeader = classnames('font-bold', 'uppercase', centeredText);
export const groupSubheader = classnames('text-xs', centeredText);
export const textContainer = classnames(
  'flex',
  'flex-col',
  'justify-start',
  'items-center',
  'lg:items-start',
  'mb-4'
);

export const textWrapper = classnames('py-5', 'mx-5', 'lowercase');

export const retailersLink = classnames(
  'mx-auto',
  'hover:underline',
  'text-xs',
  'text-blue-600'
);

export const retailerRow = classnames('flex', 'pb-2');

export const retailerImage = classnames('w-14', 'mr-4');

// sign up for notification
const formLoading = classnames('opacity-50');
const formNotLoading = classnames('opacity-100');
export const signUpForm = (isLoading: boolean): string =>
  classnames(
    { [formLoading]: isLoading, [formNotLoading]: !isLoading },
    'flex',
    'space-x-2',
    'max-w-max',
    'px-4',
    'mx-auto',
    'items-start'
  );

export const borderNone = classnames(
  'border',
  'rounded-lg',
  'flex',
  'flex-col',
  'bg-nzxt-light-grey-50'
);
export const signUpFormButton = classnames(
  'lg:ml-3',
  'disabled:cursor-not-allowed',
  'pt-3'
);
export const signUpFormSubmitSvg = (isActive: boolean): string =>
  classnames('stroke-current', 'text-nzxt-light-grey-800', {
    [formLoading]: isActive,
    [formNotLoading]: !isActive,
  });

export const signUpFormViewButtonSvg = classnames(
  'stroke-current',
  'text-white',
  'ml-2'
);

export const signUpheading = classnames(
  'text-lg',
  'font-extrabold',
  'tracking-tight',
  'text-nzxt-light-grey-900'
);
export const signUpLegalLink = classnames(
  'text-nzxt-light-grey-800',
  'font-medium',
  'underline',
  'text-xxs'
);

export const signUpLegalArea = classnames('w-2/4', 'mx-auto');
export const signUpLegalText = classnames(
  'text-nzxt-light-grey-800',
  'font-medium',
  'text-xs'
);
export const notifyTextContainer = classnames(
  'flex',
  'flex-col',
  'justify-start',
  'items-center',
  'mb-2'
);
export const notifyContainer = classnames('mb-4', 'lg:mb-2', 'pb-4', 'lg:pb-0');
export const notificationButton = classnames('mx-auto', 'w-full', 'mb-1');
export const selectStyles = classnames('w-full', 'md:max-w-max');
export const buttonWrapper = (noPadding: boolean): string =>
  classnames('w-full', {
    [paddingBottom]: !noPadding,
  });
