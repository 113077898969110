import { FC, memo } from 'react';
import { useTranslation as t } from '@utils/hooks';
import type { DatoProductDownloads } from '@framework/api/types';
import DownloadsItem from './DownloadsItem';

import * as styles from './styles';

type Props = {
  downloadsData: DatoProductDownloads[];
};

const getFileType = (mimeType): string =>
  mimeType.replace(/.*\/|\.[^.]*$/g, '');

const getLabel = (fileType: string): string => {
  if (fileType.includes('pdf')) {
    return 'downloads';
  }
  if (fileType?.includes('exe') || fileType?.includes('zip')) {
    return 'drivers';
  }
  return 'miscellaneous';
};

const getNewDataObject = (arr: DatoProductDownloads[]): any => {
  const dd = arr.reduce((acc, item) => {
    const thing = getLabel(getFileType(item?.file?.mimeType));
    acc[thing] = acc[thing] || [];
    acc[thing].push(item);
    return acc;
  }, {});

  return dd;
};

const DownloadsByType: FC<Props> = ({ downloadsData }) => {
  const DOWNLOADS_DATA = getNewDataObject(downloadsData);
  const DOC_LABEL = t('documentation_label');
  const DRIVERS_LABEL = t('drivers_label');
  const MISC_LABEL = t('miscellaneous_label');

  return (
    <>
      {Array.isArray(DOWNLOADS_DATA?.downloads) &&
        DOWNLOADS_DATA?.downloads?.length > 0 && (
          <>
            <h3 className={styles.subheading}>{DOC_LABEL}</h3>
            <div className={styles.downloadsGrid}>
              {DOWNLOADS_DATA?.downloads.map((item, index) => (
                <DownloadsItem
                  item={item}
                  key={item?.name}
                  secondToLast={DOWNLOADS_DATA.downloads.length - 2 === index}
                  fileType={getFileType(item?.file?.mimeType)}
                />
              ))}
            </div>
          </>
        )}

      {Array.isArray(DOWNLOADS_DATA?.drivers) &&
        DOWNLOADS_DATA?.drivers?.length > 0 && (
          <>
            <h3 className={styles.subheading}>{DRIVERS_LABEL}</h3>
            <div className={styles.downloadsGrid}>
              {DOWNLOADS_DATA?.drivers.map((item, index) => (
                <DownloadsItem
                  item={item}
                  key={item?.name}
                  secondToLast={DOWNLOADS_DATA.drivers.length - 2 === index}
                  fileType={getFileType(item?.file?.mimeType)}
                />
              ))}
            </div>
          </>
        )}

      {Array.isArray(DOWNLOADS_DATA?.miscellaneous) &&
        DOWNLOADS_DATA?.miscellaneous?.length > 0 && (
          <>
            <h3 className={styles.subheading}>{MISC_LABEL}</h3>
            <div className={styles.downloadsGrid}>
              {DOWNLOADS_DATA?.miscellaneous.map((item, index) => (
                <DownloadsItem
                  item={item}
                  key={item?.name}
                  secondToLast={
                    DOWNLOADS_DATA.miscellaneous.length - 2 === index
                  }
                  fileType={getFileType(item?.file?.mimeType)}
                />
              ))}
            </div>
          </>
        )}
    </>
  );
};

export default memo(DownloadsByType);
