import { classnames } from '@tailwindcss-classnames';

const darkGreyBg = classnames(
  'bg-gradient-to-r',
  'from-nzxt-dark-grey-500',
  'via-nzxt-dark-grey-600',
  'to-nzxt-dark-grey-900'
);
const blueBg = classnames(
  'bg-gradient-to-l',
  'from-blue-400',
  'via-blue-500',
  'to-blue-600'
);

export const box = (backgroundColor: string): string =>
  classnames(
    'flex',
    'flex-col',
    'items-center',
    'justify-items-center',
    'rounded-lg',
    'p-8',
    {
      [darkGreyBg]: backgroundColor === 'dark grey',
      [blueBg]: backgroundColor === 'blue',
    }
  );

export const textBox = classnames(
  'grid',
  'justify-items-center',
  'text-center',
  'my-auto'
);
export const heading = classnames(
  'text-white',
  'font-bold',
  'text-2xl',
  'md:text-3xl'
);

export const headingContainer = classnames('mb-3', 'md:mb-6');
export const whiteText = classnames('text-white', 'pt-1', 'text-xxs');
export const descriptionText = classnames(
  'text-white',
  'pt-1',
  'text-sm',
  'max-w-sm'
);
export const heroHeading = classnames('h4', 'text-white', 'leading-none');
export const subHeading = classnames(
  'text-2xl',
  'text-white',
  'leading-none',
  'font-thin'
);

export const button = classnames('w-full', 'ml-0', 'mr-0', 'max-w-max');
export const primaryButton = classnames(
  'border-white',
  'border-2',
  'text-white',
  'px-4',
  '2xl:px-10',
  'min-w-120',
  'md:min-w-140',
  'rounded-md',
  'whitespace-nowrap',
  'h-11',
  'flex',
  'items-center'
);
export const externalLink = (asButton: boolean): string =>
  classnames(button, 'text-white', { [primaryButton]: asButton });

export const buttonGroup = classnames(
  'pt-4',
  'text-center',
  'flex',
  'items-center',
  'justify-center',
  'lg:flex-row',
  'flex-col',
  'space-y-2',
  'lg:space-y-0',
  'lg:space-x-6',
  'w-full'
);
