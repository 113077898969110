import { memo, FC } from 'react';
import Image from 'next/legacy/image';
import { useTranslation as t } from '@utils/hooks';
import ButtonLink from '@components/ButtonLink';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import * as styles from './styles';

type Link = {
  linkType: string;
  linkLabel: string;
  asButton?: boolean;
  externalLinkRef?: {
    url: string;
  };
  internalLink?: {
    _modelApiKey: string;
    pageType: string;
    slug: string;
    parentCategory?: { slug?: string };
    id: string;
  };
  id: string;
};

type Props = {
  title: string;
  description: string;
  firstButton?: Link;
  secondButton?: Link;
  smallPrint?: string;
  background: string;
  logo?: string;
};

const StaticCta: FC<Props> = ({
  title,
  description,
  firstButton,
  secondButton,
  smallPrint,
  background,
  logo,
}) => {
  const HEADING = t(title);
  const SUBHEADING = t(description);
  const FIRST_BUTTON_LABEL = t(firstButton?.linkLabel ?? 'learn_more');
  const SECOND_BUTTON_LABEL = t(secondButton?.linkLabel ?? 'learn_more');
  const SMALLPRINT = t(smallPrint ?? 'learn_more');

  return (
    <div className={styles.box(background)}>
      <div className={styles.textBox}>
        {(title || logo) && (
          <div className={styles.headingContainer}>
            {!logo ? (
              <h4 className={styles.heading}>{HEADING}</h4>
            ) : (
              <Image src={logo} alt={HEADING} width={220} height={32} />
            )}
          </div>
        )}

        <p className={styles.descriptionText}>{SUBHEADING}</p>

        {(firstButton || secondButton) && (
          <div className={styles.buttonGroup}>
            {(firstButton?.internalLink?.id ||
              firstButton?.externalLinkRef?.url) &&
            firstButton?.linkLabel ? (
              <ButtonLink
                buttonStyle="primary"
                asButton={firstButton?.asButton}
                href={
                  firstButton?.internalLink?.id
                    ? getCmsLinkUrl({
                        // eslint-disable-next-line no-underscore-dangle
                        contentType: firstButton?.internalLink?._modelApiKey,
                        pageType: firstButton?.internalLink?.pageType,
                        slug: firstButton?.internalLink?.slug,
                        parentSlug:
                          firstButton?.internalLink?.parentCategory?.slug,
                      })
                    : firstButton?.externalLinkRef?.url
                }
                dark
                customMargin
                className={styles.button}
                internalLink={Boolean(
                  // eslint-disable-next-line no-underscore-dangle
                  firstButton?.internalLink?._modelApiKey
                )}
              >
                {FIRST_BUTTON_LABEL}
              </ButtonLink>
            ) : null}

            {(secondButton?.internalLink?.id ||
              secondButton?.externalLinkRef?.url) &&
              secondButton?.linkLabel &&
              (secondButton?.internalLink?.id ? (
                <ButtonLink
                  buttonStyle="primary"
                  dark
                  asButton={secondButton?.asButton}
                  href={getCmsLinkUrl({
                    // eslint-disable-next-line no-underscore-dangle
                    contentType: secondButton?.internalLink?._modelApiKey,
                    pageType: secondButton?.internalLink?.pageType,
                    slug: secondButton?.internalLink?.slug,
                    parentSlug:
                      secondButton?.internalLink?.parentCategory?.slug,
                  })}
                  internalLink
                  className={styles.button}
                >
                  {SECOND_BUTTON_LABEL}
                </ButtonLink>
              ) : (
                secondButton?.externalLinkRef?.url && (
                  <a
                    href={secondButton?.externalLinkRef?.url}
                    className={styles.externalLink(secondButton?.asButton)}
                  >
                    {SECOND_BUTTON_LABEL}
                  </a>
                )
              ))}
          </div>
        )}
        {smallPrint && <p className={styles.whiteText}>{SMALLPRINT}</p>}
      </div>
    </div>
  );
};

export default memo(StaticCta);
