import { FC, memo } from 'react';
import type { DatoProductDownloads } from '@framework/api/types';
import { CAM_CTA, HELP_CTA } from '@constants';
import { useTranslation as t } from '@utils/hooks';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import StaticCta from '@components/PDP/StaticCta';
import DownloadsByType from './DownloadsByType';
import * as styles from './styles';

type Props = {
  downloadsData: DatoProductDownloads[];
};

const Downloads: FC<Props> = ({ downloadsData }) => {
  const DOWNLOADS = t('downloads');

  return (
    <SectionWrapper noSpacing>
      <ContentWrapper>
        {downloadsData.length > 0 && (
          <>
            <h2 id="downloads" className="h5 mb-12 scroll-mt-[12rem]">
              {DOWNLOADS}
            </h2>
            <div className={styles.detailItemWrapper}>
              <DownloadsByType downloadsData={downloadsData} />
            </div>
          </>
        )}
        <div className={styles.downloadsWrapper}>
          <StaticCta {...CAM_CTA} />
          <StaticCta {...HELP_CTA} />
        </div>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default memo(Downloads);
