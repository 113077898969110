import { classnames } from '@tailwindcss-classnames';

// header section
export const headerWrapper = classnames('justify-between', 'flex');

export const header = (isSubheading: boolean): string =>
  classnames('mb-6', 'md:mb-12', 'text-2xl', 'md:text-3xl', {
    'font-bold': !isSubheading,
    'text-nzxt-light-grey-600': isSubheading,
  });

export const srOnly = classnames('sr-only');

// slider controls
export const arrowContainer = (hideOnDesktop = false): string =>
  classnames('hidden', { 'lg:block': !hideOnDesktop });

const activeArrow = classnames('text-nzxt-light-grey-800', 'dark:text-white');
const inactiveArrow = classnames(
  'text-nzxt-dark-grey-100',
  'dark:text-nzxt-light-grey-700'
);
export const arrow = (isLast: boolean): string =>
  classnames(
    { [activeArrow]: !isLast, [inactiveArrow]: isLast },
    'h-8',
    'w-8',
    'stroke-current',
    'transition',
    'duration-150',
    'ease-in-out'
  );

export const pipContainer = (hideOnDesktop = true): string =>
  classnames('flex', 'justify-center', { 'lg:hidden': hideOnDesktop }, 'mt-4');

const pipBase = classnames(
  'border-0',
  'w-3',
  'h-3',
  'rounded-full',
  'mx-2',
  'p-1',
  'cursor-pointer',
  'focus:outline-none'
);

const activePip = classnames('bg-nzxt-light-grey-800');
const inactivePip = classnames('bg-nzxt-dark-grey-100');

export const pip = (isActive: boolean): string =>
  classnames(pipBase, {
    [activePip]: isActive,
    [inactivePip]: !isActive,
  });
